import React, { forwardRef, useEffect, useState } from 'react';
import axios from 'axios';
import {
  HiX,
  HiChevronUp,
  HiChevronDown,
  HiDotsHorizontal,
  HiInformationCircle,
} from 'react-icons/hi';
import * as Dialog from '@radix-ui/react-dialog';
import toast, { Toaster } from 'react-hot-toast';
import { format, parse } from 'date-fns';
import { useAuth0 } from '@auth0/auth0-react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import * as Tooltip from '@radix-ui/react-tooltip';
import RolesBulkMultiSelectDropdown from '../../components/RolesBulkMultiSelectDropdown';
import SingleBulkSelectDropdown from '../../components/SingleBulkSelectDropdown';
import { baseUrl, headers, qliksenseAttributes } from '../../app/lookups';
import AlertMessage from '../../components/AlertMessage';
import { useUsersAPI } from '../../app/userContext';
import BulkDateField from '../../components/date-fields/BulkDateField';
import SenseAttributesBulkMultiSelectDropdown from '../../components/SenseAttributesBulkMultiSelectDropdown';
import MultiInput from '../../components/MultiInput';
import TableCell from '../../components/table/TableCell';
import TableDateCell from '../../components/table/TableDateCell';
import TableSelectCell from '../../components/table/TableSelectCell';
import TableSingleSelectCell from '../../components/table/TableSingleSelectCell';
import TableCheckboxCell from '../../components/table/TableCheckboxCell';
import Glossary from '../../components/Glossary';
import MultiCheckboxCell from '../../components/table/MultiCheckboxCell';

const auth0ConnectionName = process.env.REACT_APP_AUTH0_CONNECTION_NAME;

const ApproveUsersModal = forwardRef(
  ({ setShowModal, usersToApprove }, ref) => {
    const [onlyPreRegUsers, setOnlyPreRegUsers] = useState(usersToApprove);
    const [editedRows, setEditedRows] = useState({});
    const [requestsResponses, setRequestsResponses] = useState([]);
    const [showGlossaryModal, setShowGlossaryModal] = useState(false);
    const [primaryTrustChangeCounter, setPrimaryTrustChangeCounter] =
      useState(0);

    const { roles, trusts, groups, organisationDefaults } = useUsersAPI();
    const { user: admin } = useAuth0();
    const columnHelper = createColumnHelper();

    const formatDate = (dateString) => {
      if (!dateString) return '';
      const parsedDate = parse(dateString, 'd-MMM-yy', new Date());
      return format(parsedDate, 'yyyy-MM-dd');
    };

    useEffect(() => {
      const usersToRegister = usersToApprove.filter(
        (user) => user.source === 'pre_reg_db',
      );
      setOnlyPreRegUsers(usersToRegister);
    }, [usersToApprove]);

    useEffect(() => {
      if (organisationDefaults.length === 0) {
        toast.loading('Organisation Defaults Loading', {
          id: 'org_defaults',
        });
      } else {
        toast.success('Organisation Defaults Loaded', {
          id: 'org_defaults',
        });
      }
    }, [organisationDefaults]);

    useEffect(() => {
      if (organisationDefaults.length > 0) {
        setOnlyPreRegUsers((prevUsers) =>
          prevUsers.map((user) => {
            const matchingDefault = organisationDefaults.find(
              ([trust]) => trust === user.primary_trust,
            );
            if (matchingDefault) {
              const defaultRoles = [];
              const [
                ,
                startDate,
                endDate,
                // eslint-disable-next-line no-unused-vars
                appUsers,
                // eslint-disable-next-line no-unused-vars
                appReporting,
                platformPlus,
                platform,
                platformBenchmarking,
                // eslint-disable-next-line no-unused-vars
                catalogue,
              ] = matchingDefault;
              if (platformPlus === '1') {
                defaultRoles.push('PLATFORM_PLUS');
              }
              if (platform === '1') {
                defaultRoles.push('PLATFORM_STANDARD');
              }
              if (platformBenchmarking === '1') {
                defaultRoles.push('BENCHMARKING');
              }
              return {
                ...user,
                start_date:
                  startDate === ''
                    ? ''
                    : startDate
                    ? formatDate(startDate)
                    : user.start_date,
                end_date:
                  endDate === ''
                    ? ''
                    : endDate
                    ? formatDate(endDate)
                    : user.end_date,
                roles: defaultRoles,
              };
            }
            return user;
          }),
        );
      }
    }, [organisationDefaults, primaryTrustChangeCounter, roles]);

    const buildStatus = (info) => {
      let status;
      if (info.row.original.source === 'db') {
        status = info.row.original.blocked ? 'blocked' : 'active';
      } else {
        status = info.row.original.approval_granted
          ? 'awaiting activation'
          : info.row.original.date_approval_denied !== null
          ? 'rejected'
          : info.row.original.approval_requested
          ? 'awaiting approval'
          : info.row.original.date_approval_denied !== null
          ? 'rejected'
          : 'new';
      }
      return (
        <span
          ref={ref}
          // className={`px-3 py-1 justify-center uppercase leading-wide font-bold text-xs rounded-full shadow-sm
          className={`px-3 py-1 inline-flex justify-center items-center uppercase leading-wide font-bold text-xs rounded-full shadow-sm            
            ${
              status.startsWith('active')
                ? 'bg-platform-cta-success-200 text-platform-cta-success-600'
                : status.startsWith('blocked')
                ? 'bg-platform-cta-error-200 text-platform-cta-error-600'
                : status.startsWith('awaiting activation')
                ? 'bg-yellow-100 text-platform-cta-grey-600'
                : status.startsWith('awaiting approval')
                ? 'bg-platform-secondary-orange-200 text-platform-secondary-orange-600'
                : status.startsWith('rejected')
                ? 'bg-platform-cta-error-200 text-platform-cta-error-600'
                : status.startsWith('new')
                ? 'bg-platform-primary-blue-200 text-platform-primary-blue-800'
                : null
            }`}>
          {status}
        </span>
      );
    };

    const columns = [
      columnHelper.accessor((row) => row.firstname, {
        id: 'firstname',
        header: 'First Name *',
        cell: TableCell,
        meta: {
          type: 'text',
        },
        enableGlobalFilter: true,
        sortingFn: 'nameSort',
      }),
      columnHelper.accessor((row) => row.surname, {
        id: 'surname',
        header: 'Last Name *',
        cell: TableCell,
        meta: {
          type: 'text',
        },
        enableGlobalFilter: true,
        sortingFn: 'nameSort',
      }),
      columnHelper.accessor((row) => row.nickname, {
        id: 'nickname',
        header: 'Nickname *',
        cell: TableCell,
        meta: {
          type: 'text',
        },
        enableGlobalFilter: true,
        sortingFn: 'nameSort',
      }),
      columnHelper.accessor((row) => row.profile_picture, {
        id: 'profile_picture',
        header: 'Profile Picture URL',
        cell: TableCell,
        meta: {
          type: 'text',
        },
        enableGlobalFilter: true,
        sortingFn: 'nameSort',
      }),
      columnHelper.accessor((row) => row.email, {
        id: 'email',
        header: 'Email *',
        cell: TableCell,
        meta: {
          type: 'text',
        },
        enableGlobalFilter: true,
        sortingFn: 'nameSort',
      }),
      columnHelper.accessor((row) => row.job_title, {
        id: 'job_title',
        header: 'Job Title *',
        cell: TableCell,
        meta: {
          type: 'text',
        },
        enableSorting: false,
      }),
      columnHelper.accessor((row) => row.primary_trust, {
        id: 'primary_trust',
        fieldName: 'primary_trust',
        header: 'Primary Trust *',
        cell: TableSingleSelectCell,
        meta: {
          type: 'select',
          options: trusts,
          setCounter: setPrimaryTrustChangeCounter,
        },
        enableSorting: false,
      }),
      columnHelper.accessor((row) => row.primary_group, {
        id: 'primary_group',
        fieldName: 'primary_group',
        header: 'AdviseInc Group *',
        cell: TableSingleSelectCell,
        meta: {
          type: 'select',
          options: groups,
          filterKey: 'trust',
          rowKey: 'primary_trust',
        },
        enableSorting: false,
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        cell: (info) => buildStatus(info),
        enableSorting: false,
      }),
      columnHelper.accessor((row) => row.start_date, {
        id: 'start_date',
        header: 'Start Date *',
        cell: TableDateCell,
        meta: {
          type: 'date',
        },
      }),
      columnHelper.accessor((row) => row.end_date, {
        id: 'end_date',
        header: 'End Date *',
        cell: TableDateCell,
        meta: {
          type: 'date',
        },
      }),
      columnHelper.accessor((row) => row.sense_attributes, {
        fieldName: 'sense_attributes',
        id: 'sense_attributes',
        cell: TableSelectCell,
        meta: {
          type: 'select',
          options: qliksenseAttributes,
        },
        header: 'Sense Attributes *',
        enableSorting: false,
      }),
      columnHelper.accessor((row) => row.roles, {
        fieldName: 'roles',
        id: 'roles',
        header: 'Roles *',
        cell: TableSelectCell,
        meta: {
          type: 'select',
          options: roles,
        },
        enableSorting: false,
      }),

      columnHelper.accessor((row) => row.default_org, {
        fieldName: 'default_org',
        id: 'default_org',
        header: 'Default View *',
        cell: TableSingleSelectCell,
        meta: {
          type: 'select',
          options: [
            { name: 'org', value: 'org', label: 'org' },
            { name: 'group', value: 'group', label: 'group' },
          ],
          defaultValue: 'group',
        },
        enableSorting: false,
      }),
      columnHelper.accessor((row) => row.default_landing_page, {
        fieldName: 'default_landing_page',
        id: 'default_landing_page',
        header: 'Default Landing Page *',
        enableSorting: false,
        cell: TableCell,
        meta: {
          type: 'text',
          defaultValue: '/',
        },
      }),
      columnHelper.accessor((row) => row.app_type, {
        fieldName: 'app_type',
        id: 'app_type',
        header: 'Default App Type *',
        enableSorting: false,
        cell: TableCell,
        meta: {
          type: 'text',
          defaultValue: 'app',
        },
      }),
      columnHelper.accessor((row) => row.notes, {
        fieldName: 'notes',
        id: 'notes',
        header: 'Notes',
        enableSorting: false,
        cell: TableCell,
        meta: {
          type: 'text',
          defaultValue: '',
        },
      }),
      columnHelper.accessor((row) => row.locked_to_group, {
        fieldName: 'locked_to_group',
        id: 'locked_to_group',
        cell: TableCheckboxCell,
        header: 'Locked To Group',
        enableSorting: false,
      }),

      columnHelper.accessor((row) => row.hide_kpi, {
        fieldName: 'hide_kpi',
        id: 'hide_kpi',
        cell: TableCheckboxCell,
        header: 'hide kpi',
        enableSorting: false,
      }),
    ];

    const table = useReactTable({
      data: onlyPreRegUsers,
      columns,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      filterFns: {
        emailFilter: (row, columnId, filterValue) =>
          filterValue.length === 0
            ? row
            : onlyPreRegUsers
                .filter((user) =>
                  user.email.toLowerCase().includes(filterValue.toLowerCase()),
                )
                .map((user) => user.id)
                .includes(row.original.id),
      },
      globalFilterFn: 'emailFilter',
      sortingFns: {
        nameSort: (rowA, rowB) =>
          rowA.original.surname > rowB.original.surname ? 1 : -1,
      },
      meta: {
        editedRows,
        setEditedRows,
        revertData: (rowIndex) => {
          setOnlyPreRegUsers((old) =>
            old.map((row, index) =>
              index === rowIndex ? onlyPreRegUsers[rowIndex] : row,
            ),
          );
        },
        updateData: (rowIndex, columnId, value) => {
          setOnlyPreRegUsers((old) =>
            old.map((row, index) => {
              if (index === rowIndex) {
                return {
                  ...old[rowIndex],
                  [columnId]: value,
                };
              }
              return row;
            }),
          );
        },
      },
    });

    const [displayAlert, setDisplayAlert] = useState(false);

    const validateRequiredFields = (user) => {
      const requiredFields = [
        'firstname',
        'surname',
        'nickname',
        'email',
        'job_title',
        'primary_trust',
        'primary_group',
        'start_date',
        'end_date',
        'sense_attributes',
        'roles',
        'default_org',
        'default_landing_page',
        'app_type',
      ];

      // return requiredFields.every(
      //   (field) => user[field] && user[field].toString().trim() !== '',
      // );
      const missingFields = requiredFields.filter(
        (field) => !user[field] || user[field].toString().trim() === '',
      );
      return missingFields;
    };

    const handleSubmit = async () => {
      const changeDateFormat = (dateString) => {
        const parsedDate = parse(
          dateString,
          "yyyy-MM-dd'T'HH:mm:ss'Z'",
          new Date(),
        );
        return format(parsedDate, 'yyy-MM-dd');
      };

      const usersWithMissingFields = onlyPreRegUsers
        .map((user) => ({
          email: user.email,
          missingFields: validateRequiredFields(user),
        }))
        .filter((user) => user.missingFields.length > 0);

      if (usersWithMissingFields.length > 0) {
        let alertMessage = 'Please fill in the following required fields:\n\n';
        usersWithMissingFields.forEach((user) => {
          alertMessage += `User ${user.email}:\n`;
          user.missingFields.forEach((field) => {
            alertMessage += `- ${field}\n`;
          });
          alertMessage += '\n';
        });
        // eslint-disable-next-line no-alert
        alert(alertMessage);
        return;
      }

      // eslint-disable-next-line no-alert
      const answer = window.confirm(
        `The submission cannot be reversed. Are you sure you want to continue for ${onlyPreRegUsers.length} users?`,
      );
      if (answer) {
        try {
          onlyPreRegUsers.forEach(async (user) => {
            const userData = {
              email: user.email,
              firstname: user.firstname,
              surname: user.surname,
              nickname: user.nickname,
              start_date: changeDateFormat(user.start_date),
              end_date: changeDateFormat(user.end_date),
              primary_trust: user.primary_trust,
              primary_group: user.primary_group,
              job_title: user.job_title,
              sense_attributes: user.sense_attributes,
              roles: user.roles,
              locked_to_group: user.locked_to_group ? 'True' : 'False',
              notes: user.notes,
              connection: auth0ConnectionName,
              admin_id: admin.email,
              preferences: {
                default_org: user.default_org,
                default_landing_page: user.default_landing_page,
                app_type: user.app_type,
                nickname: user.nickname,
                profile_picture: user.profile_picture,
                job_role: 'Other',
                other_job_role: 'Default',
                hide_kpi: user.hide_kpi ? 'True' : 'False',
              },
            };
            const responseData = await axios.post(
              `${baseUrl}/user-service/approve-users`,
              [
                {
                  first_name: user.firstname,
                  surname: user.surname,
                  email: user.email,
                  job: user.job_title,
                  organisation: user.organisation,
                  other: user.other,
                  date_requested: user.date_requested,
                  requested_by: user.requested_by,
                  requested_by_email: user.requested_by_email,
                  requested_by_job_title: user.requested_by_job_title,
                  crm_code: user.crm_code,
                  crm_name: user.crm_name,
                  applications_subscription: user.applications_subscription,
                  lifecycle_stage: user.lifecycle_stage,
                  lifecycle_status: user.lifecycle_status,
                  approver: admin.email,
                  subscription_status: user.subscription_status,
                  subscription_types: user.subscription_types,
                  user_data: userData,
                  activation_date: changeDateFormat(user.start_date),
                },
              ],
              { headers },
            );
            setRequestsResponses((prevResponses) => [
              ...prevResponses,
              responseData?.data ?? responseData?.message,
            ]);
          });
          setDisplayAlert(true);
        } catch (error) {
          console.error('Error submitting user data:', error);
        }
      } else {
        console.log('Thing was not saved to the database.');
      }
    };

    return (
      <div ref={ref}>
        <Dialog.Content className="fixed inset-0 bg-white overflow-hidden flex flex-col">
          <div className="w-full h-full bg-white flex flex-col">
            <div className="flex flex-shrink-0 items-start justify-between p-5 border-b border-solid border-platform-primary-grey-200 rounded-t">
              <div className="text-3xl font-bold text-platform-primary-grey-800">
                Approve Users
              </div>
              <div className="flex space-x-4">
                <button
                  type="button"
                  aria-label="open-glossary"
                  className="border-2 rounded-md p-2 font-bold bg-platform-primary-grey-800  border-platform-primary-grey-800 text-white "
                  onClick={() => setShowGlossaryModal(true)}>
                  <HiInformationCircle />
                </button>
                <button
                  type="button"
                  aria-label="close-modal-button"
                  className="border-2 rounded-md p-2 font-bold bg-platform-cta-error-800  border-platform-cta-error-800 text-white hover:bg-white hover:text-platform-cta-error-800"
                  onClick={() => {
                    setOnlyPreRegUsers([]);
                    setShowModal(false);
                    window.location.reload();
                  }}>
                  <HiX />
                </button>
              </div>
            </div>
            <div className="flex-grow relative p-6 overflow-y-scroll">
              {onlyPreRegUsers.length > 0 ? (
                <table className="w-full text-sm text-left text-platform-primary-grey-800 z-50">
                  <thead className="sticky top-0 text-xs text-platform-primary-grey-800 uppercase bg-platform-secondary-grey-200">
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            className="p-2">
                            {header.isPlaceholder ? null : (
                              <div className="flex flex-col items-center">
                                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                                <div
                                  className={`flex items-ce67ytgjfter ${
                                    header.column.getCanSort()
                                      ? 'cursor-pointer select-none'
                                      : ''
                                  }`}
                                  onClick={header.column.getToggleSortingHandler()}>
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext(),
                                  )}
                                  {header.column.getIsSorted().toString() ===
                                  'asc' ? (
                                    <HiChevronUp className="ml-1 text-xl" />
                                  ) : header.column.getIsSorted() === 'desc' ? (
                                    <HiChevronDown className="ml-1 text-xl" />
                                  ) : header.column.getCanSort() ? (
                                    <HiDotsHorizontal className="ml-1 text-xl" />
                                  ) : null}
                                </div>
                              </div>
                            )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row) => (
                      <tr
                        className="bg-white hover:bg-platform-ainc-grey-400 border border-platform-primary-grey-200"
                        key={row.id}>
                        {/* flex flex-col md:flex-row gap-4 */}
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id} className="py-1 px-1.5">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                    <tr className="bg-platform-ainc-grey-200 border border-platform-primary-grey-200">
                      <td aria-label="Mute volume">
                        <b className="pl-6">Bulk updates</b>{' '}
                      </td>
                      <td aria-label="Mute volume" />
                      <td aria-label="Mute volume" />
                      <td aria-label="Mute volume" />
                      <td aria-label="Mute volume" />
                      <td aria-label="Mute volume" />
                      <td aria-label="group-text-input">
                        <div className="w-[240px]">
                          <SingleBulkSelectDropdown
                            fieldName="primary_trust"
                            placeholder="Primary Trust Bulk"
                            usersToApprove={onlyPreRegUsers}
                            setUsersToApprove={setOnlyPreRegUsers}
                            originalOptions={trusts}
                            keyName="primary_trust"
                          />
                        </div>
                      </td>
                      <td aria-label="group-text-input">
                        <div className="w-[240px] py-2 px-1">
                          <SingleBulkSelectDropdown
                            fieldName="primary_group"
                            placeholder="Primary Group Bulk"
                            usersToApprove={onlyPreRegUsers}
                            setUsersToApprove={setOnlyPreRegUsers}
                            originalOptions={groups}
                            keyName="primary_group"
                            filterKey="trust"
                            rowKey="primary_trust"
                          />
                        </div>
                      </td>
                      <td aria-label="Mute volume" />
                      <td aria-label="start-date-input-control">
                        <div className="w-[280px] py-2 px-1">
                          <BulkDateField
                            key="start_date"
                            dateFieldName="start_date"
                            usersToApprove={onlyPreRegUsers}
                            setUsersToApprove={setOnlyPreRegUsers}
                          />
                        </div>
                      </td>
                      <td aria-label="end-date-input-control">
                        <div className="w-[280px] py-2 px-1 ">
                          <BulkDateField
                            fieldName="end_date"
                            key="end_date"
                            dateFieldName="end_date"
                            usersToApprove={onlyPreRegUsers}
                            setUsersToApprove={setOnlyPreRegUsers}
                          />
                        </div>
                      </td>
                      <td aria-label="senseattributes-select-input">
                        <div className="w-[240px] py-2 px-1">
                          <SenseAttributesBulkMultiSelectDropdown
                            usersToApprove={onlyPreRegUsers}
                            setUsersToApprove={setOnlyPreRegUsers}
                            qliksenseAttributes={qliksenseAttributes}
                            defaultValues={[
                              '^security_licence_permission',
                              '^control_tower_app',
                              '^adviseinc_platform_stream',
                            ]}
                          />
                        </div>
                      </td>
                      <td aria-label="roles-multi-select-input">
                        <div className="w-[240px] py-2 px-1">
                          <RolesBulkMultiSelectDropdown
                            usersToApprove={onlyPreRegUsers}
                            roleAttributes={roles}
                            setUsersToApprove={setOnlyPreRegUsers}
                            defaultValues={[
                              'BENCHMARKING',
                              'PLATFORM_STANDARD',
                            ]}
                          />
                        </div>
                      </td>
                      <td aria-label="group-text-input">
                        <div className="w-[240px]">
                          <SingleBulkSelectDropdown
                            fieldName="default_org"
                            placeholder="Default View Bulk"
                            usersToApprove={onlyPreRegUsers}
                            setUsersToApprove={setOnlyPreRegUsers}
                            originalOptions={[
                              { name: 'org', value: 'org', label: 'org' },
                              { name: 'group', value: 'group', label: 'group' },
                            ]}
                            keyName="default_org"
                          />
                        </div>
                      </td>
                      <td aria-label="group-text-input">
                        <div className="w-[200px]">
                          <MultiInput
                            fieldName="default_landing_page"
                            placeholder="Bulk Default Landing Page"
                            usersToApprove={onlyPreRegUsers}
                            setUsersToApprove={setOnlyPreRegUsers}
                            defaultValue="/"
                          />
                        </div>
                      </td>
                      <td aria-label="group-text-input">
                        <div className="w-[240px]">
                          <MultiInput
                            fieldName="app_type"
                            placeholder="Bulk Default App Type"
                            usersToApprove={onlyPreRegUsers}
                            setUsersToApprove={setOnlyPreRegUsers}
                            defaultValue="app"
                          />
                        </div>
                      </td>
                      <td aria-label="Mute volume" />
                      <td aria-label="group-text-input">
                        <div className="w-[240px]">
                          <MultiCheckboxCell
                            fieldName="locked_to_group"
                            usersToApprove={onlyPreRegUsers}
                            setUsersToApprove={setOnlyPreRegUsers}
                          />
                        </div>
                      </td>
                      <td aria-label="group-text-input">
                        <div className="w-[240px]">
                          <MultiCheckboxCell
                            fieldName="hide_kpi"
                            usersToApprove={onlyPreRegUsers}
                            setUsersToApprove={setOnlyPreRegUsers}
                          />
                        </div>
                      </td>
                      <td aria-label="Mute volume">
                        <div className="w-[20px]" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <div className="p-6 flex-auto max-h-[22rem] m-auto text-center">
                  No valid preregistration users selected. Already registered
                  users are not allowed to be created again.
                </div>
              )}
            </div>
            <div>
              <Toaster position="bottom-left" />
            </div>
            <div className="p-2 border-t border-solid border-platform-primary-grey-200 rounded-b">
              {displayAlert &&
                requestsResponses &&
                requestsResponses.length !== 0 &&
                requestsResponses.map((response) =>
                  response?.creation_response?.response?.status === 'success' ||
                  response?.update_response?.response?.status === 'success' ? (
                    <AlertMessage
                      success
                      message="User has been approved and added for activation"
                      closeAlert={() => setDisplayAlert(false)}
                    />
                  ) : displayAlert ? (
                    <AlertMessage
                      success={false}
                      message={`User was not successfully approved. ${
                        response?.message && `Error: ${response?.message}`
                      }`}
                      closeAlert={() => setDisplayAlert(false)}
                    />
                  ) : null,
                )}
              <div className="flex justify-end my-2 mt-auto bottom-0">
                <button
                  className="mx-1 bg-platform-cta-error-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-cta-error-800 hover:border-platform-cta-error-800 border-1"
                  type="button"
                  aria-label="cancel-button"
                  onClick={() => {
                    setOnlyPreRegUsers([]);
                    setShowModal(false);
                    window.location.reload();
                  }}>
                  Cancel
                </button>
                <button
                  className="mx-1 bg-platform-cta-success-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-cta-success-800 hover:border-platform-cta-success-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800 mt-auto"
                  type="button"
                  aria-label="back-button"
                  onClick={handleSubmit}>
                  Approve Users
                </button>
              </div>
            </div>
          </div>
        </Dialog.Content>
        <Dialog.Root
          open={showGlossaryModal}
          onOpenChange={setShowGlossaryModal}>
          <Tooltip.Provider>
            <Tooltip.Root>
              <Tooltip.Trigger asChild>
                <div>
                  <Dialog.Trigger asChild>
                    <button
                      type="button"
                      aria-label="reject-users-button"
                      className="mx-1 my-0.5 px-1 py-1 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white disabled:bg-platform-secondary-grey-200 font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
                      <HiInformationCircle className="text-xl" />
                    </button>
                  </Dialog.Trigger>
                </div>
              </Tooltip.Trigger>
              <Tooltip.Portal>
                <Tooltip.Content
                  side="bottom"
                  className="rounded-full bg-platform-primary-grey-800 text-white text-xs px-2 py-1">
                  Glossary
                  <Tooltip.Arrow className="fill-platform-primary-grey-800" />
                </Tooltip.Content>
              </Tooltip.Portal>
            </Tooltip.Root>
          </Tooltip.Provider>
          <Dialog.Portal>
            <Dialog.Overlay className="bg-platform-primary-grey-800 bg-opacity-50 data-[state=open]:animate-overlayShow fixed inset-0" />
            <Glossary
              admin={admin}
              setShowModal={setShowGlossaryModal}
              ref={ref}
            />
          </Dialog.Portal>
        </Dialog.Root>
      </div>
    );
  },
);

export default ApproveUsersModal;
