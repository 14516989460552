import React from 'react';
import { HiCheck, HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import * as Checkbox from '@radix-ui/react-checkbox';
import * as Label from '@radix-ui/react-label';
import { useFormContext } from 'react-hook-form';
import RolesDualListBox from './RolesDualListbox';
import SingleDateField from './date-fields/SingleDateField';
import UserPreferences from './UserPreferences';
import { qliksenseAttributes } from '../app/lookups';

export default function UserForm({ page, setPage }) {
  const {
    register,
    formState: { errors },
    reset,
    watch,
    setValue,
    control,
  } = useFormContext();

  return (
    <div className="relative p-6 flex-auto">
      <div className="flex divide-x divide-platform-primary-grey-200">
        <div className="flex-col">
          {page === 1 ? (
            <div className="flex">
              <div className="mr-2">
                <div className="font-bold text-platform-primary-grey-800">
                  User Profile
                </div>
                <div className="text-xs text-platform-primary-grey-800 pb-2">
                  Please complete all fields
                </div>
                <div className="flex justify-between">
                  <div className="flex flex-col mx-2 mt-2 mb-3">
                    <div className="flex justify-between">
                      <Label.Root
                        htmlFor="firstname"
                        className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                        First Name
                      </Label.Root>
                      {errors.firstname && (
                        <div className="text-xs font-bold">
                          This field is required
                        </div>
                      )}
                    </div>
                    <input
                      className={`appearance-none block w-[14rem] py-3 px-4 leading-tight rounded-md border ${
                        watch('firstname') === ''
                          ? 'border-platform-primary-orange-800'
                          : 'border-platform-primary-grey-200'
                      } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0`}
                      id="firstname"
                      type="text"
                      placeholder="First Name"
                      {...register('firstname', { required: true })}
                    />
                  </div>
                  <div className="mx-2 mt-2 mb-3">
                    <div className="flex justify-between">
                      <Label.Root
                        htmlFor="surname"
                        className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                        Surname
                      </Label.Root>
                      {errors.surname && (
                        <div className="text-xs font-bold">
                          This field is required
                        </div>
                      )}
                    </div>
                    <input
                      className={`appearance-none block w-[14rem] py-3 px-4 leading-tight rounded-md border ${
                        watch('surname') === ''
                          ? 'border-platform-primary-orange-800'
                          : 'border-platform-primary-grey-200'
                      } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0`}
                      id="surname"
                      type="text"
                      placeholder="Surname"
                      {...register('surname', { required: true })}
                    />
                  </div>
                </div>
                <div className="mx-2 mt-2 mb-3">
                  <div className="flex justify-between">
                    <Label.Root
                      htmlFor="email"
                      className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                      Email
                    </Label.Root>
                    {errors.email && (
                      <div className="text-xs font-bold">
                        This field is required
                      </div>
                    )}
                  </div>
                  <input
                    className={`appearance-none block w-maxFull py-3 px-4 leading-tight rounded-md border ${
                      watch('email') === ''
                        ? 'border-platform-primary-orange-800'
                        : 'border-platform-primary-grey-200'
                    } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0`}
                    type="email"
                    id="email"
                    placeholder="Email"
                    {...register('email', { required: true })}
                  />
                </div>
                <div className="mx-2 mt-2 mb-3">
                  <div className="flex justify-between">
                    <Label.Root
                      htmlFor="job"
                      className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                      Job Title
                    </Label.Root>
                    {errors.job && (
                      <div className="text-xs font-bold">
                        This field is required
                      </div>
                    )}
                  </div>
                  <input
                    className={`appearance-none block w-maxFull py-3 px-4 leading-tight rounded-md border ${
                      watch('job') === ''
                        ? 'border-platform-primary-orange-800'
                        : 'border-platform-primary-grey-200'
                    } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0`}
                    type="text"
                    id="job"
                    placeholder="Job Title"
                    {...register('job', { required: true })}
                  />
                </div>
                <div className="mx-2 mt-2 mb-3">
                  <div className="flex justify-between">
                    <Label.Root
                      htmlFor="nickname"
                      className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                      Nickname
                    </Label.Root>
                    {errors.nickname && (
                      <div className="text-xs font-bold">
                        This field is required
                      </div>
                    )}
                  </div>
                  <input
                    className={`appearance-none block w-maxFull py-3 px-4 leading-tight rounded-md border ${
                      watch('nickname') === ''
                        ? 'border-platform-primary-orange-800'
                        : 'border-platform-primary-grey-200'
                    } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0`}
                    type="text"
                    id="nickname"
                    placeholder="Nickname"
                    {...register('nickname', { required: true })}
                  />
                </div>
                <div className="mx-2 mt-2 mb-3">
                  <div className="flex justify-between">
                    <Label.Root
                      htmlFor="primaryTrust"
                      className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                      Primary Organisation
                    </Label.Root>
                    {errors.primaryTrust && (
                      <div className="text-xs font-bold">
                        This field is required
                      </div>
                    )}
                  </div>
                  <input
                    className={`appearance-none block w-maxFull py-3 px-4 leading-tight rounded-md border ${
                      watch('primaryTrust') === ''
                        ? 'border-platform-primary-orange-800'
                        : 'border-platform-primary-grey-200'
                    } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0`}
                    type="text"
                    id="primaryTrust"
                    placeholder="Primary Organisation"
                    {...register('primaryTrust', { required: true })}
                  />
                </div>
                <div className="mx-2 mt-2 mb-3">
                  <div className="flex justify-between">
                    <Label.Root
                      htmlFor="primaryGroup"
                      className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                      Primary Group
                    </Label.Root>
                    {errors.primaryGroup && (
                      <div className="text-xs font-bold">
                        This field is required
                      </div>
                    )}
                  </div>
                  <input
                    className={`appearance-none block w-maxFull py-3 px-4 leading-tight rounded-md border ${
                      watch('primaryGroup') === ''
                        ? 'border-platform-primary-orange-800'
                        : 'border-platform-primary-grey-200'
                    } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0`}
                    type="text"
                    id="primaryGroup"
                    placeholder="Primary Group"
                    {...register('primaryGroup', { required: true })}
                  />
                </div>
                <div className="flex justify-between">
                  <div className="mx-2 mt-2 mb-3">
                    <div className="flex justify-between">
                      <Label.Root
                        htmlFor="startDate"
                        className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                        Start Date
                      </Label.Root>
                      {errors.startDate && (
                        <div className="text-xs font-bold">
                          This field is required
                        </div>
                      )}
                    </div>
                    <div className="w-[240px]">
                      <SingleDateField
                        key="startDate"
                        fieldId="startDate"
                        register={register}
                        setValue={setValue}
                        reset={reset}
                        watch={watch}
                        control={control}
                      />
                    </div>
                  </div>
                  <div className="mx-2 mt-2 mb-3">
                    <div className="flex justify-between">
                      <Label.Root
                        htmlFor="endDate"
                        className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                        End Date
                      </Label.Root>
                      {errors.endDate && (
                        <div className="text-xs font-bold">
                          This field is required
                        </div>
                      )}
                    </div>
                    <div className="w-[240px]">
                      <SingleDateField
                        key="endDate"
                        fieldId="endDate"
                        register={register}
                        setValue={setValue}
                        reset={reset}
                        watch={watch}
                        control={control}
                      />
                    </div>
                  </div>
                </div>
                <div className="mx-2 mt-2 mb-3">
                  <div className="flex justify-between">
                    <Label.Root
                      htmlFor="lockToGroup"
                      className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                      Locked To Group?
                    </Label.Root>
                    {errors.lockToGroup && (
                      <div className="text-xs font-bold">
                        This field is required
                      </div>
                    )}
                  </div>
                  <div className="flex justify-start items-center">
                    <Checkbox.Root
                      className="flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-md border border-platform-primary-grey-200 text-white focus:ring-0 data-[state=checked]:border-platform-primary-orange-800 data-[state=checked]:bg-platform-primary-orange-800"
                      checked={watch('lockToGroup')}
                      onCheckedChange={() =>
                        setValue('lockToGroup', !watch('lockToGroup'))
                      }>
                      <Checkbox.Indicator className="">
                        <HiCheck />
                      </Checkbox.Indicator>
                    </Checkbox.Root>
                  </div>
                </div>
                <div className="mx-2 mt-2 mb-3">
                  <div className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                    Notes
                  </div>
                  <textarea
                    className={`appearance-none block w-maxFull py-3 px-4 leading-tight rounded-md border ${
                      watch('notes') === ''
                        ? 'border-platform-primary-orange-800'
                        : 'border-platform-primary-grey-200'
                    } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0`}
                    rows={5}
                    id="notes"
                    placeholder="Notes"
                    {...register('notes')}
                  />
                </div>
              </div>
              <div className="mx-4">
                <div className="ml-2 font-bold text-platform-primary-grey-800">
                  Sense Attributes Settings
                </div>
                {qliksenseAttributes
                  .filter((app) => !app.mandatory)
                  .map((app) => (
                    <div className="flex mb-2 p-2 items-center" key={app.id}>
                      <Checkbox.Root
                        className="flex h-[20px] w-[20px] appearance-none items-center justify-center rounded-md border border-platform-primary-grey-200 text-white focus:ring-0 data-[state=checked]:border-platform-primary-orange-800 data-[state=checked]:bg-platform-primary-orange-800"
                        checked={watch('senseAttributes').includes(app.value)}
                        id={app.value}
                        onCheckedChange={() =>
                          watch('senseAttributes').includes(app.value)
                            ? setValue(
                                'senseAttributes',
                                watch('senseAttributes').filter(
                                  (a) => a !== app.value,
                                ),
                              )
                            : setValue('senseAttributes', [
                                ...watch('senseAttributes'),
                                app.value,
                              ])
                        }>
                        <Checkbox.Indicator className="">
                          <HiCheck />
                        </Checkbox.Indicator>
                      </Checkbox.Root>
                      <Label.Root
                        className="text-platform-primary-grey-800 px-2 text-sm font-medium"
                        htmlFor={app.value}>
                        {app.label}
                      </Label.Root>
                    </div>
                  ))}
              </div>
            </div>
          ) : page === 2 ? (
            <div className="flex">
              <div className="mr-2">
                <div className="font-bold text-platform-primary-grey-800">
                  User Preferences
                </div>
                <div className="text-xs text-platform-primary-grey-800 pb-2">
                  Please select initial preferences
                </div>
                <UserPreferences />
              </div>
            </div>
          ) : (
            <div className="mr-2 h-auto">
              <div className="font-bold text-platform-primary-grey-800 pb-2">
                User Roles
              </div>
              <RolesDualListBox />
            </div>
          )}
          <div className={`flex ${page > 1 ? 'justify-start' : 'justify-end'}`}>
            {page > 1 ? (
              <div className=" flex items-center justify-start">
                <button
                  type="button"
                  onClick={() => setPage(page - 1)}
                  className="mx-1 bg-platform-primary-orange-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-primary-orange-800 hover:border-platform-primary-orange-800 border-1">
                  <div className="flex items-center">
                    <HiChevronLeft className="text-2xl" /> Previous Page
                  </div>
                </button>
              </div>
            ) : null}
            {page < 3 ? (
              <div className="flex items-center justify-end">
                <button
                  type="button"
                  onClick={() => setPage(page + 1)}
                  className="mx-1 bg-platform-primary-orange-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-primary-orange-800 hover:border-platform-primary-orange-800 border-1">
                  <div className="flex items-center">
                    Next Page <HiChevronRight className="text-2xl" />{' '}
                  </div>
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
