import React, { useRef, useState } from 'react';
import {
  HiDownload,
  HiMail,
  HiMinusCircle,
  HiPencil,
  // HiUserGroup,
  HiTrash,
  HiInformationCircle,
} from 'react-icons/hi';
import { FcApprove, FcDisapprove, FcFeedback } from 'react-icons/fc';

import * as Dialog from '@radix-ui/react-dialog';
import * as Tooltip from '@radix-ui/react-tooltip';
import ApproveUsersModal from './ApproveUsersModal';
// import BulkCreateUsersModal from './BulkCreateUsersModal';
import DeleteUserModal from './DeleteUserModal';
import ResetPasswordModal from './ResetPasswordModal';
import BlockUserModal from './BlockUserModal';
import RejectUsersModal from './RejectUsersModal';
import Glossary from '../../components/Glossary';
import RequestApprovalModal from './RequestApprovalModal';

export default function UsersTableBar({
  admin,
  selectedUsers,
  setSelectedUsers,
  tableTitle,
}) {
  // const [showBulkCreateUsersModal, setShowBulkCreateUsersModal] =
  //   useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showBlockedUserModal, setShowBlockedUserModal] = useState(false);
  const [showApproveUsersModal, setShowApproveUsersModal] = useState(false);
  const [showApprovalRequestedModal, setShowApprovalRequestedModal] =
    useState(false);
  const [showRejectUsersModal, setShowRejectUsersModal] = useState(false);
  const [showGlossaryModal, setShowGlossaryModal] = useState(false);

  const ref = useRef();

  const handleEditUsers = () => {
    setShowEditUserModal(true);
  };

  return (
    <div className="flex px-2 py-1 justify-between items-center bg-platform-ainc-grey-400 border border-platform-secondary-grey-200">
      <div className="flex">
        <div>
          {/* <Dialog.Root
            open={showCreateUserModal}
            onOpenChange={setShowCreateUserModal}>
            <Dialog.Trigger>
              <Tooltip.Provider>
                <Tooltip.Root>
                  <Tooltip.Trigger asChild>
                    <button
                      type="button"
                      aria-label="add-user-button"
                      className="mx-1 my-0.5 px-1 py-1 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white disabled:bg-platform-secondary-grey-200 font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
                      <span>
                        <HiUserAdd className="text-xl" />
                      </span>
                    </button>
                  </Tooltip.Trigger>
                  <Tooltip.Portal>
                    <Tooltip.Content
                      side="bottom"
                      className="rounded-full bg-platform-primary-grey-800 text-white text-xs px-2 py-1">
                      Create User
                      <Tooltip.Arrow className="fill-platform-primary-grey-800" />
                    </Tooltip.Content>
                  </Tooltip.Portal>
                </Tooltip.Root>
              </Tooltip.Provider>
            </Dialog.Trigger>
            <Dialog.Portal>
              <Dialog.Overlay className="bg-platform-primary-grey-800 bg-opacity-50 data-[state=open]:animate-overlayShow fixed inset-0" />
              <CreateUserModal
                admin={admin}
                setShowModal={setShowCreateUserModal}
                ref={ref}
              />
            </Dialog.Portal>
          </Dialog.Root> */}
          <Dialog.Root
            open={showApproveUsersModal}
            onOpenChange={setShowApproveUsersModal}>
            <Tooltip.Provider>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <div>
                    <Dialog.Trigger asChild>
                      <button
                        type="button"
                        aria-label="approve-users-button"
                        className="mx-1 my-0.5 px-1 py-1 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white disabled:bg-platform-secondary-grey-200 font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
                        <FcApprove className="text-xl" />
                      </button>
                    </Dialog.Trigger>
                  </div>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                  <Tooltip.Content
                    side="bottom"
                    className="rounded-full bg-platform-primary-grey-800 text-white text-xs px-2 py-1">
                    Approve Users
                    <Tooltip.Arrow className="fill-platform-primary-grey-800" />
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
            </Tooltip.Provider>
            <Dialog.Portal>
              <Dialog.Overlay className="bg-platform-primary-grey-800 bg-opacity-50 data-[state=open]:animate-overlayShow fixed inset-0" />
              <ApproveUsersModal
                admin={admin}
                shouldCloseOnOverlayClick={false}
                setShowModal={setShowApproveUsersModal}
                usersToApprove={selectedUsers}
                setUsersToApprove={setSelectedUsers}
                ref={ref}
              />
            </Dialog.Portal>
          </Dialog.Root>
          <Dialog.Root
            open={showRejectUsersModal}
            onOpenChange={setShowRejectUsersModal}>
            <Tooltip.Provider>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <div>
                    <Dialog.Trigger asChild>
                      <button
                        type="button"
                        aria-label="reject-users-button"
                        className="mx-1 my-0.5 px-1 py-1 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white disabled:bg-platform-secondary-grey-200 font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
                        <FcDisapprove className="text-xl" />
                      </button>
                    </Dialog.Trigger>
                  </div>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                  <Tooltip.Content
                    side="bottom"
                    className="rounded-full bg-platform-primary-grey-800 text-white text-xs px-2 py-1">
                    Reject Users
                    <Tooltip.Arrow className="fill-platform-primary-grey-800" />
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
            </Tooltip.Provider>
            <Dialog.Portal>
              <Dialog.Overlay className="bg-platform-primary-grey-800 bg-opacity-50 data-[state=open]:animate-overlayShow fixed inset-0" />
              <RejectUsersModal
                admin={admin}
                setShowModal={setShowRejectUsersModal}
                usersToReject={selectedUsers}
                ref={ref}
              />
            </Dialog.Portal>
          </Dialog.Root>
        </div>
        <div>
          {/* <Dialog.Root
            open={showBulkCreateUsersModal}
            onOpenChange={setShowBulkCreateUsersModal}>
            <Tooltip.Provider>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <div>
                    <Dialog.Trigger asChild>
                      <button
                        type="button"
                        aria-label="user-group-button"
                        onClick={() => setShowBulkCreateUsersModal(true)}
                        disabled
                        className="mx-1 my-0.5 px-1 py-1 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white disabled:bg-platform-secondary-grey-200 font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
                        <HiUserGroup className="text-xl" />
                      </button>
                    </Dialog.Trigger>
                  </div>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                  <Tooltip.Content
                    side="bottom"
                    className="rounded-full bg-platform-primary-grey-800 text-white text-xs px-2 py-1">
                    Create Multiple Users
                    <Tooltip.Arrow className="fill-platform-primary-grey-800" />
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
            </Tooltip.Provider>
            <Dialog.Portal>
              <Dialog.Overlay className="bg-platform-primary-grey-800 bg-opacity-50 data-[state=open]:animate-overlayShow fixed inset-0" />
              <BulkCreateUsersModal
                setShowModal={setShowBulkCreateUsersModal}
                ref={ref}
              />
            </Dialog.Portal>
          </Dialog.Root> */}
          {/* showApprovalRequestedModal, setShowApprovalRequestedModal */}

          <Dialog.Root
            open={showApprovalRequestedModal}
            onOpenChange={setShowApprovalRequestedModal}>
            <Tooltip.Provider>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <div>
                    <Dialog.Trigger asChild>
                      <button
                        type="button"
                        aria-label="approval-requested-users-button"
                        className="mx-1 my-0.5 px-1 py-1 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white disabled:bg-platform-secondary-grey-200 font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
                        <FcFeedback className="text-xl" />
                      </button>
                    </Dialog.Trigger>
                  </div>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                  <Tooltip.Content
                    side="bottom"
                    className="rounded-full bg-platform-primary-grey-800 text-white text-xs px-2 py-1">
                    Update Status to Awaiting Approval
                    <Tooltip.Arrow className="fill-platform-primary-grey-800" />
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
            </Tooltip.Provider>
            <Dialog.Portal>
              <Dialog.Overlay className="bg-platform-primary-grey-800 bg-opacity-50 data-[state=open]:animate-overlayShow fixed inset-0" />
              <RequestApprovalModal
                admin={admin}
                setShowModal={setShowApprovalRequestedModal}
                usersToRequestApproval={selectedUsers}
                ref={ref}
              />
            </Dialog.Portal>
          </Dialog.Root>
        </div>
        <div>
          <Dialog.Root
            open={showEditUserModal}
            onOpenChange={setShowEditUserModal}>
            <Tooltip.Provider>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <div>
                    <Dialog.Trigger asChild>
                      <button
                        type="button"
                        aria-label="edit-user-pencil-button"
                        disabled
                        onClick={() => handleEditUsers()}
                        className="mx-1 my-0.5 px-1 py-1 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white disabled:bg-platform-secondary-grey-200 font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
                        <HiPencil className="text-xl" />
                      </button>
                    </Dialog.Trigger>
                  </div>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                  <Tooltip.Content
                    side="bottom"
                    className="rounded-full bg-platform-primary-grey-800 text-white text-xs px-2 py-1">
                    Edit Users
                    <Tooltip.Arrow className="fill-platform-primary-grey-800" />
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
            </Tooltip.Provider>
          </Dialog.Root>
        </div>
        <div>
          <Dialog.Root
            open={showResetPasswordModal}
            onOpenChange={setShowResetPasswordModal}>
            <Tooltip.Provider>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <div>
                    <Dialog.Trigger asChild>
                      <button
                        type="button"
                        aria-label="reset-password-button"
                        disabled
                        onClick={() => setShowResetPasswordModal(true)}
                        className="mx-1 my-0.5 px-1 py-1 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white disabled:bg-platform-secondary-grey-200 font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
                        <HiMail className="text-xl" />
                      </button>
                    </Dialog.Trigger>
                  </div>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                  <Tooltip.Content
                    side="bottom"
                    className="rounded-full bg-platform-primary-grey-800 text-white text-xs px-2 py-1">
                    Reset Password
                    <Tooltip.Arrow className="fill-platform-primary-grey-800" />
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
            </Tooltip.Provider>
            <Dialog.Portal>
              <Dialog.Overlay className="bg-platform-primary-grey-800 bg-opacity-50 data-[state=open]:animate-overlayShow fixed inset-0" />
              <ResetPasswordModal
                setShowModal={setShowResetPasswordModal}
                ref={ref}
              />
            </Dialog.Portal>
          </Dialog.Root>
        </div>
        <div>
          <Dialog.Root
            open={showBlockedUserModal}
            onOpenChange={setShowBlockedUserModal}>
            <Tooltip.Provider>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <div>
                    <Dialog.Trigger asChild>
                      <button
                        type="button"
                        aria-label="block-user-button"
                        disabled
                        onClick={() => setShowBlockedUserModal(true)}
                        className="mx-1 my-0.5 px-1 py-1 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white disabled:bg-platform-secondary-grey-200 font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
                        <HiMinusCircle className="text-xl" />
                      </button>
                    </Dialog.Trigger>
                  </div>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                  <Tooltip.Content
                    side="bottom"
                    className="rounded-full bg-platform-primary-grey-800 text-white text-xs px-2 py-1">
                    Block Users
                    <Tooltip.Arrow className="fill-platform-primary-grey-800" />
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
            </Tooltip.Provider>
            <Dialog.Portal>
              <Dialog.Overlay className="bg-platform-primary-grey-800 bg-opacity-50 data-[state=open]:animate-overlayShow fixed inset-0" />
              <BlockUserModal
                admin={admin}
                setShowModal={setShowBlockedUserModal}
                usersToBlock={selectedUsers}
                setUsersToBlock={setSelectedUsers}
                ref={ref}
              />
            </Dialog.Portal>
          </Dialog.Root>
        </div>
        <div>
          <Dialog.Root
            open={showDeleteUserModal}
            onOpenChange={setShowDeleteUserModal}>
            <Tooltip.Provider>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <div>
                    <Dialog.Trigger asChild>
                      <button
                        type="button"
                        aria-label="delete-user-button"
                        onClick={() => setShowDeleteUserModal(true)}
                        className="mx-1 my-0.5 px-1 py-1 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white disabled:bg-platform-secondary-grey-200 font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
                        <HiTrash className="text-xl" />
                      </button>
                    </Dialog.Trigger>
                  </div>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                  <Tooltip.Content
                    side="bottom"
                    className="rounded-full bg-platform-primary-grey-800 text-white text-xs px-2 py-1">
                    Delete Users
                    <Tooltip.Arrow className="fill-platform-primary-grey-800" />
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
            </Tooltip.Provider>
            <Dialog.Portal>
              <Dialog.Overlay className="bg-platform-primary-grey-800 bg-opacity-50 data-[state=open]:animate-overlayShow fixed inset-0" />
              <DeleteUserModal
                admin={admin}
                setShowModal={setShowDeleteUserModal}
                usersToDelete={selectedUsers}
                ref={ref}
              />
            </Dialog.Portal>
          </Dialog.Root>
        </div>
        <div>
          <Dialog.Root
            open={showGlossaryModal}
            onOpenChange={setShowGlossaryModal}>
            <Tooltip.Provider>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <div>
                    <Dialog.Trigger asChild>
                      <button
                        type="button"
                        aria-label="reject-users-button"
                        className="mx-1 my-0.5 px-1 py-1 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white disabled:bg-platform-secondary-grey-200 font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
                        <HiInformationCircle className="text-xl" />
                      </button>
                    </Dialog.Trigger>
                  </div>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                  <Tooltip.Content
                    side="bottom"
                    className="rounded-full bg-platform-primary-grey-800 text-white text-xs px-2 py-1">
                    Glossary
                    <Tooltip.Arrow className="fill-platform-primary-grey-800" />
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
            </Tooltip.Provider>
            <Dialog.Portal>
              <Dialog.Overlay className="bg-platform-primary-grey-800 bg-opacity-50 data-[state=open]:animate-overlayShow fixed inset-0" />
              <Glossary
                admin={admin}
                setShowModal={setShowGlossaryModal}
                ref={ref}
              />
            </Dialog.Portal>
          </Dialog.Root>
        </div>
      </div>
      <div className="flex items-center">
        <div className="text-lg font-medium text-gray-800">{tableTitle}</div>
      </div>
      <div className="flex">
        <Tooltip.Provider>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <button
                type="button"
                aria-label="download-button"
                disabled
                className="mx-1 my-0.5 px-1 py-1 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white disabled:bg-platform-secondary-grey-200 font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
                <span>
                  <HiDownload className="text-xl" />
                </span>
              </button>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content
                side="bottom"
                className="rounded-full bg-platform-primary-grey-800 text-white text-xs px-2 py-1">
                Export Users
                <Tooltip.Arrow className="fill-platform-primary-grey-800" />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      </div>
    </div>
  );
}
